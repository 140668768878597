<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="物料名称/物料编码" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable multiple collapse-tags>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.types" placeholder="请选择出入类型" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.warehouses" placeholder="请选择仓库" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="warehouse in warehouses" :key="warehouse.id" :label="warehouse.name" :value="warehouse.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getSuppliers(params)">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="suppliers" :size="theme.size" @sort-change="sortChange">
				<el-table-column label="出入类型" min-width="80" :formatter="({common_type}) => { return types[common_type] || '未知模型'; }"></el-table-column>
				<el-table-column label="出入单号" min-width="150" prop="common.no"></el-table-column>
				<el-table-column label="物料编码" min-width="100" prop="material.in_code"></el-table-column>
				<el-table-column label="物料名称" min-width="120" prop="material.name"></el-table-column>
				<el-table-column label="所属仓库" min-width="80" prop="warehouse.name"></el-table-column>
				<el-table-column label="出入数量" min-width="80" :formatter="({income, amount}) => { return (income ? '+ ' : '- ')+amount; }"></el-table-column>
				<el-table-column label="结后库存" min-width="80" prop="inventory"></el-table-column>
				<el-table-column label="备注说明" min-width="140" prop="remarks"></el-table-column>
				<el-table-column label="创建时间" min-width="150" prop="created_at" v-if="theme.width >= 1420"></el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getSuppliers({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getSuppliers({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<show-order ref="show_dialog"></show-order>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTable from '@/components/main-table';
	import showOrder from '@/pages/oms/orders/show';

	export default {
		components: {
			mainTable,
			showOrder
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			shops () {
				return this.models.shops ? this.models.shops.data : [];
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y/MM/DD');
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getSuppliers(this.params);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.material_name+'】供应吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_SHEETS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.material_name+'】供应吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_SHEETS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			async getSuppliers (p, l = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_SHEETS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.suppliers = result.data;
				this.warehouses = result.warehouses;
				this.types = result.types;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				types: [],
				warehouses: [],
				suppliers: [],
				supplier: {},
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getSuppliers(this.params, true)
		}
	};
</script>